.typing {
    width: 70px;
    height: 37px;
    position: relative;
    padding: 10px;
    margin-left: 5px;
    background: #e6e6e6;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .typing-dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background: #8d8c91;
    border-radius: 50%;
    opacity: 0;
    animation: loadingFade 1s infinite;
  }
  
  .typing-dot:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }